import React from 'react'
import { IconType } from 'react-icons'
import {FaChevronRight} from 'react-icons/fa'
import { Card } from "react-bootstrap"
import { editedPermission, permission } from '../models/clientUserPermission'

export interface iClientUserPermissionsProps {
    darkThemeEnabled: boolean,
    title: string,
    permissions: permission[],
    changedPermissions: editedPermission
    icon: IconType,  
    onExpandUpdate: () => void,
    isEdited: boolean,
};

const ClientUserPermissionsWidget: React.FunctionComponent<iClientUserPermissionsProps> = (props) => {
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';

    const calculateActivePermissions = () => {
        let activePermissons = props.permissions.filter(perm => perm.isEnabled);
        
        let calculatedActivePermissions: number = activePermissons.length
        + props.changedPermissions.grantedPermissions.length
        - props.changedPermissions.removedPermissions.length;
        
        return calculatedActivePermissions;
    }

    return (
        <Card id='clientPermissionsCard' className={`text-center ${cardTheme} devtool-card noselect`} >
            <Card.Header className={textTheme} onClick={props.onExpandUpdate}>
            <div className={props.isEdited ? `clientPermissionsTitle-isEdited` : `clientPermissionsTitle-notEdited-${theme}`}>
                {React.createElement(props.icon, {className: `widgetIcon-${theme}`})}
                <span className={`titleSpan-${theme}`}>
                    {props.title} ( {calculateActivePermissions()} / {props.permissions.length} )
                </span>
                <FaChevronRight className={`widgetChevron-${theme}`}/>
            </div>
            </Card.Header>
        </Card>
    )
}

export default ClientUserPermissionsWidget;