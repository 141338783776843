import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaUser, FaCog } from 'react-icons/fa';
import * as cookies from 'gps-utilities/lib/cookies';
import { WebBaseURL, devshackUser } from './models';

export interface INavigationProps {
  effectEnabled: boolean
  darkThemeEnabled: boolean
  loggedInUser: devshackUser
  toggleEffects: ( a: boolean) => void
  setTheme: ( a: boolean ) => void
};

const toggleBackgroundCookie = (cookie: string, toggle: boolean) => {
  cookies.deleteCookie(cookie);
  cookies.setCookie(cookie, toggle.toString());
  return toggle;
}

const logout = () => {
  // TODO - rbrunney - 20240411 - Need a state update here so that way our page actually changes
  cookies.deleteCookie('DevtoolsAuthKey');
  var tenantId = "8f1a8af8-14f1-4431-9f7e-76f33dc9cc0f";
  window.open(`https://login.microsoftonline.com/${tenantId}/oauth2/logout?post_logout_redirect_uri=${WebBaseURL}`, "_self")
}

const Navigation: React.FunctionComponent<INavigationProps> = props => {
  const menuVariant = props.darkThemeEnabled ? 'dark' : '';

  const handleEffectsToggle = () => {
    props.toggleEffects(toggleBackgroundCookie('backgroundEffect', !props.effectEnabled))
  }
  const handleThemeToggle = () => {
    props.setTheme(toggleBackgroundCookie('backgroundTheme', !props.darkThemeEnabled))
  }

  return (
      <div>
    {[undefined].map((expand) => (
      <Navbar key={expand} variant="dark" expand={expand} className="mb-3 navBar">
        <Container fluid>
          <Navbar.Brand href="/dashboard">Developer Tools</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav>
                <NavDropdown title={'Impersonation'} menuVariant={menuVariant}>
                  <NavDropdown.Item href="/impersonator">
                    Impersonator
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/authenticatorCode">
                    Authenticator Code
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/clientUserLookup">User Lookup</Nav.Link>
                <Nav.Link href="/clientUserPermissionsEditor">Permissions</Nav.Link>
              </Nav>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <NavDropdown align={{ lg: 'end' }} title={<FaCog/>} menuVariant={menuVariant}>
                  <NavDropdown.Item onClick={handleEffectsToggle}>
                    {props.effectEnabled ? "Disable Particle Effect" : "Enable Particle Effect"}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleThemeToggle}>
                    {props.darkThemeEnabled ? "Disable Dark Theme" : "Enable Dark Theme"}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown align={{ lg: 'end' }} title={<FaUser/>} menuVariant={menuVariant}>
                  {
                    props.loggedInUser.isAdmin && <NavDropdown.Item href='/adminUser'>Master Control</NavDropdown.Item>
                  }
                  <NavDropdown.Item onClick={logout}>
                    Sign Out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    ))}

    </div>
  )
}

export default Navigation;