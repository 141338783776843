import React from 'react';
import { Outlet } from 'react-router-dom';
import Background from './Background/Background';
import Navigation from './Navigation';
import { devshackUser } from './models';

export interface ILayoutProps {
    effectEnabled: boolean
    darkThemeEnabled: boolean
    loggedInUser: devshackUser
    toggleEffects: ( a: boolean) => void
    setTheme: ( a: boolean ) => void
};

const Layout: React.FunctionComponent<ILayoutProps> = props => {

    return (
        <div className='devtool-page-layout'>
            <Background 
                darkThemeEnabled={props.darkThemeEnabled}
                effectEnabled={props.effectEnabled}
            />
            <Navigation 
                darkThemeEnabled={props.darkThemeEnabled}
                effectEnabled={props.effectEnabled}
                loggedInUser={props.loggedInUser}
                toggleEffects={props.toggleEffects}
                setTheme={props.setTheme}
            />
            <Outlet />
        </div>
    )
}

export default Layout;
