import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { useCopyToClipboard } from 'usehooks-ts';
import { APIBaseURL, client, clientUser} from '../models';
import axios from 'axios';
import ErrorBanner from '../Reusables/ErrorBanner';
import { Button, FormCheck } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import PermissionFilterModal from './PermissionFilterModal';
import { HiOutlineSearch } from 'react-icons/hi';

export interface IClientUserLookupProps {
    isModal?: boolean
    onClose?: () => void
    onConfirm?: (clientUser: clientUser) => void
    db?: string
    darkThemeEnabled: boolean
};

const ClientUserLookup: React.FunctionComponent<IClientUserLookupProps> = (props) => {
    let dbs: string[] = [];
    let dbClients: client[] = [];
    const [currentError, setCurrentError] = useState('')
    const [clientUsers, setClientUsers] = useState<clientUser[] | null>(null);
    const [value, copy] = useCopyToClipboard();
    const [db, setDb] = useState(props.db ? props.db : "Select Database");
    const [showCopyMessage, setCopyMessage] = useState('');
    const [dispayCopiedMessage, setDisplayCopyMsg] = useState(false);
    let isDisabled = db === "Select Database";


    let initialClient: client = {
        name: '',
        id: ''
    };

    const currentYear = new Date().getFullYear();
    let isLoading = !clientUsers;
    const [databases, setDbs] = useState(dbs);
    useEffect(() => {
        axios.get(APIBaseURL + '/home/dbNames', {
            withCredentials: true
        })
        .then((response) => {
            setDbs(response.data);
        })
        .catch((e) => { setCurrentError(e + " failed to get databases"); });
    }, []);
    
    const [client, setClient] = useState(initialClient);
    const [clients, setClients] = useState(dbClients);
    useEffect(() => {
        if(db !== 'Select Database') {
            axios.get(APIBaseURL + `/user/clients?databaseName=${db}`, {
            withCredentials: true
        })
            .then((response) => {
                var data = response.data;
                data.forEach((d: client) => {
                    dbClients.push(d);
                });
                setClients(dbClients);
                setFilteredClients(dbClients);
                if(!client.id){
                    setClient(dbClients[0]);
                }
            })
            .catch((e) => { setCurrentError(e  + " failed to get clients"); });
        }
    }, [db]);

    const [filteredClients, setFilteredClients] = useState<client[] | null>(clients);
    const handleClientsDropdownSearch = (value: string) => {
        let clientUserNames: string[] = []
        const filteredData = clients!.filter(client => {
            if(client.name.toLowerCase().includes(value.toLowerCase()) && !clientUserNames.includes(client.name)) {
                clientUserNames.push(client.name);
                return client;
            }
        });
        setFilteredClients(filteredData);
    }

    const [permissionFilter, setPermissionFilter] = useState<number>();
    const [isPermissionFilterActive, setIsPermissionFilterActive] = useState<boolean>(false);
    useEffect(() => {
        if(permissionFilter != undefined) {
            axios.get(APIBaseURL + `/user/clientsWithPermission?permissionId=${permissionFilter}&databaseName=${db}`, {
                withCredentials: true
            })
            .then((response) => {
                let clientsWithPermission: client[] = [];
                response.data.forEach((client: client) => {
                    clientsWithPermission.push(client)
                });

                setFilteredClients(clientsWithPermission);
                setClient(clientsWithPermission[0])
            })
            .catch((e) => { setCurrentError(e + " permission filter failed"); });
        }
    }, [permissionFilter])
    
    const [showInactiveUsers, setShowActiveUsers] = useState(false);
    function compareLastThenFirstName(firstClientUser: clientUser, secondClientUser: clientUser) {
        if(firstClientUser["lastThenFirstName"] < secondClientUser["lastThenFirstName"]) return -1;
        if(firstClientUser["lastThenFirstName"] > secondClientUser["lastThenFirstName"]) return 1;
        return 0;
    }

    const [isFilteringAllClientUsers, setFilterToggle] = useState(false);
    useEffect(() => {
        async function fetchData() {
            const res = await axios.get(APIBaseURL + '/home/clientUsers', {
                params: {clientId: client.id},
                withCredentials: true
            });
            const data = await res.data.map((d: { userProfileId: string; lastThenFirstName: string; userName: string; isInactive: boolean; }) => {
                var client: clientUser = {
                    userProfileId: d.userProfileId,
                    lastThenFirstName: d.lastThenFirstName,
                    userName: d.userName,
                    isInactive: d.isInactive
                }    
                return client;   
            });   
            setClientUsers(data.sort(compareLastThenFirstName));
        }
        
        if(client.id && !isFilteringAllClientUsers){
            fetchData().catch(console.log);
        }
    }, [client, isFilteringAllClientUsers]);

    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';
    const buttonVariantTheme = props.darkThemeEnabled ? 'secondary' : 'outline-dark';
    const buttonOutline = props.darkThemeEnabled ? 'light' : 'secondary';
    const tableVariant = props.darkThemeEnabled ? 'dark' : '';
    const searchbarColor = props.darkThemeEnabled ? 'white' : '';
    const searchbarBackgroundColor = props.darkThemeEnabled ? '#212529' : 'transparent';

    const [filterQuery, setFilterQuery] = useState("");
    const handleSearch = (value: string) => {
        setFilterQuery(value);
    }

    const [allUsersFilter, setAllUsersFilter] = useState<string>("");
    function enterKeyPressed(event: React.KeyboardEvent) {
        if(event.key === 'Enter') {
            filterAllClientUsers(allUsersFilter);
        }
    }

    async function filterAllClientUsers(value: string) {
        if(isFilteringAllClientUsers && value.length > 2) { 
            setClientUsers(null);
            const res = await axios.get(APIBaseURL + `/user/clientUsersByFilter?name=${value}`, {
                withCredentials: true
            });

            const data = await res.data.map((d: { userProfileId: string; lastThenFirstName: string; userName: string; isMarkedAsDeleted: boolean; }) => {
                var client: clientUser = {
                    userProfileId: d.userProfileId,
                    lastThenFirstName: d.lastThenFirstName,
                    userName: d.userName,
                    isInactive: d.isMarkedAsDeleted
                }    
                return client;   
            });   
            setClientUsers(data.sort(compareLastThenFirstName));
        }
        else if(value.length <= 2) {
            setCurrentError("Please enter more than 2 characters when searching all clients");
        }
    }

    let isClientDropdownDisabled = isFilteringAllClientUsers;

    const handleActiveUserToggle = (showActive: boolean) => {
        setShowActiveUsers(showActive);
    }

    const displayCopyMessage = (val: string) => {
        setCopyMessage(val);
        setDisplayCopyMsg(true);
    }

    const updateBackground = () => {
        var background = document.getElementById('background');
        
        if(background !== undefined) {
            background!.style.filter = 'blur()';
        }

        if(props.onClose !== undefined) {
            props.onClose();
        }
    }

    useEffect(() => {
        var background = document.getElementById('background');

        if(background !== undefined && props.isModal) {
            background!.style.filter = 'blur(5px)';
        }
        
    }, [props.isModal])

    const clearBlur = () => {
        var background = document.getElementById('background');

        if(background !== undefined && props.isModal) {
            background!.style.filter = 'blur()';
        }
    }

    const getCopiedClientUser = () => {
        if(value !== null) {
            var currentClientUser = clientUsers!.filter(clientUser => clientUser.userName === value)
            return currentClientUser[0];
        }

        return {lastThenFirstName: '', userProfileId: '', userName: '', isInactive: true}
    }

    useEffect(() => {
        if(value !== null && props.onConfirm !== undefined) {
            var copiedClientUser = getCopiedClientUser();
            props.onConfirm(copiedClientUser);
            clearBlur();
            if(props.onClose !== undefined) props.onClose();
        }
    }, [value])

    return (        
        <div>
            <div className='error-banner-placement'>
                {
                    currentError !== '' 
                    ? <ErrorBanner 
                        errorMessage={currentError.toString()} 
                        closeMessage={() => {setCurrentError('')}}
                      /> : <p></p>
                }
            </div>
            <div id='clientuser-lookup' className='dashboard-card'>
                <PermissionFilterModal
                    isShowing={isPermissionFilterActive}
                    darkThemeEnabled={props.darkThemeEnabled}
                    onClose={() => { setIsPermissionFilterActive(!isPermissionFilterActive) }}
                    onSubmit={(permissionFilter: number) => { setPermissionFilter(permissionFilter) }}
                />
                <Card id='clientuser-lookup-card' className={`text-center ${cardTheme} devtool-card`} > 
                    <Card.Header className={textTheme}>
                        <div className='clientuser-header'>
                            <div className='title'>
                                <h1>Client Users</h1>
                                <>
                                    {
                                        props.isModal ? (
                                            <div className='close-btn'>
                                                <Button 
                                                    variant='secondary'
                                                    onClick={updateBackground}
                                                    >
                                                    <IoMdClose size={25} />
                                                </Button>
                                            </div>
                                        ) : (
                                            <p></p>
                                        )
                                    }
                                </>
                            </div>
                        </div>
                    </Card.Header>        
                    <Card.Body className={`cul-card ${textTheme}`}>
                        <div className='cul-db-dropdown'>
                            <span className='title'>Database Name</span>
                            <DropdownButton id={`dropdown-basic-button-${theme}`} title={db} variant={buttonVariantTheme} menuVariant={tableVariant}>
                                {
                                    databases.map((db) => (
                                        <Dropdown.Item onClick={() => setDb(db)}>{db}</Dropdown.Item>
                                    ))
                                } 
                            </DropdownButton>
                        </div>
                        <div className='cul-dropdown'>
                            <span className='title'>Client (or GPS)</span>
                            <DropdownButton id={`dropdown-basic-button-${theme}`} title={client.name} variant={buttonVariantTheme} menuVariant={tableVariant} disabled={isLoading || isClientDropdownDisabled}>
                                <div className='client-scroll-filter'>
                                    <InputGroup>
                                        <InputGroup.Text className={`filter-form-${tableVariant}`}>Search</InputGroup.Text>
                                        <Form.Control className='client-filter' style={{ backgroundColor: searchbarBackgroundColor, color: searchbarColor}}
                                            onChange={(e) => handleClientsDropdownSearch(e.target.value)}/>
                                    </InputGroup>
                                </div>
                                <div className='client-scroll'>
                                    {
                                        filteredClients!.map((c, i) => (
                                            <Dropdown.Item id={c.id} onClick={() => setClient(c)}>{c.name}</Dropdown.Item>
                                        ))
                                    }
                                </div>
                            </DropdownButton>
                            <>
                                {
                                    !isLoading && (
                                        <div className='client-permission-filter'>
                                            <>
                                                {
                                                    permissionFilter != undefined ? (
                                                        <p onClick={() => {
                                                            setPermissionFilter(undefined);
                                                            setFilteredClients(clients);
                                                            setClient(clients[0])
                                                            }}>
                                                            Remove Permission Filter
                                                        </p>
                                                    ) : 
                                                    (
                                                        <p onClick={() => { setIsPermissionFilterActive(!isPermissionFilterActive) }}>
                                                            Add Permission Filter
                                                        </p>
                                                    )
                                                }
                                            </>
                                        </div>
                                    )
                                }
                            </>
                        </div>
                        <div className='cul-filter'>
                            <span className="title">Filter</span>
                            <InputGroup className="mb-3">
                                {
                                    isFilteringAllClientUsers ? (          
                                        <>
                                            <Form.Control className='user-filter'
                                                style={{ backgroundColor: searchbarBackgroundColor, color: searchbarColor}}
                                                aria-describedby="search"
                                                disabled={isLoading || isDisabled}
                                                onChange={(e) => setAllUsersFilter(e.target.value)}
                                                onKeyUp={(e) => {enterKeyPressed(e)}}                                     
                                            />
                                            <Button className={`input-group-prepend ${cardTheme} btn-outline-${buttonOutline}`} onClick={() => {filterAllClientUsers(allUsersFilter)}}>
                                                    <HiOutlineSearch className="lookup-icon" size={15}/>
                                            </Button>      
                                        </>                          
                                    ) : (
                                        <Form.Control className='user-filter'
                                            style={{ backgroundColor: searchbarBackgroundColor, color: searchbarColor}}
                                            aria-describedby="search"
                                            onChange={(e) => handleSearch(e.target.value)}
                                            disabled={isLoading || isDisabled}
                                        />
                                    )
                                }
                                
                            </InputGroup>
                            <FormCheck>
                                <FormCheck.Input type="checkbox" 
                                    onChange={() => setFilterToggle(!isFilteringAllClientUsers)}
                                />
                                <FormCheck.Label id='filter-all-clients-label'>Filter from all clients</FormCheck.Label>
                            </FormCheck>
                        </div>            
                    </Card.Body>
                    <Card.Body >
                        <div className='copy-msg' >
                            {dispayCopiedMessage && <span className="copy-msg">{`Copied: ${showCopyMessage} to clipboard!`}</span>}
                        </div>
                        <Form className='cul-table-area'>
                            <Form.Switch 
                                className={textTheme}
                                label="Show Inactive Users"
                                onChange={() => handleActiveUserToggle(!showInactiveUsers)}
                                disabled={isLoading}
                            />
                        </Form>
                        <>
                            {isLoading ? (
                                <>
                                    {
                                        db !== 'Select Database' && (<Spinner animation='border' className='spinner'/>)
                                    }
                                </>
                            ):(
                                
                                <div className='clientusers-table'>
                                    <Table striped bordered hover variant={tableVariant}>
                                        <thead>
                                            <tr>
                                            <th>Active</th>
                                            <th>Name</th>
                                            <th>Username</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                clientUsers!.filter(cu => !cu.isInactive|| showInactiveUsers)
                                                    .filter(c => c.lastThenFirstName.toLowerCase().includes(filterQuery.toLowerCase()) || c.userName.toLowerCase().includes(filterQuery.toLowerCase()))!
                                                    .map((cut, i) => (
                                                    <tr onClick={() => {
                                                        copy(`${cut.userName}`); 
                                                        displayCopyMessage(cut.userName);
                                                        }} style={{ cursor: 'pointer', overflow: 'auto'}} >
                                                        <td>{(!cut.isInactive) ? <Form.Check checked={true} /> : ""}</td>
                                                        <td>{cut.lastThenFirstName}</td>
                                                        <td>{cut.userName}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </>
                    </Card.Body>
                    <Card.Footer className="text-muted card-footer">© {currentYear} - devSHACK</Card.Footer>
                </Card>
            </div>
        </div>
    )
}

export default ClientUserLookup;