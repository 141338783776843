import React, { useState } from 'react'
import './css/styles.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AdminUser from './components/AdminUserPage/AdminUser';
import ClientUserLookup from './components/ClientUserLookup/ClientUserLookup';
import Dashboard from './components/Dashboard/Dashboard';
import Impersonator from './components/Impersonator/Impersonator';
import Layout from './components/Layout';
import * as cookies from 'gps-utilities/lib/cookies';
import { devshackUser } from './components/models';
import Login from './components/Login/Login';
import ClientUserPermissionsEditor from './components/ClientUserPermissionsEditor/ClientUserPermissionsEditor';
import AuthenticatorCode from './components/AuthenticatorCode/AuthenticatorCode';
import OAuthSignIn from './components/Login/OAuthSignIn';

export interface IApplicationProps {}

const themeCookie = cookies.getCookie('backgroundTheme');
const backgroundEffectCookie = cookies.getCookie('backgroundEffect');

const users: devshackUser[] = [
    {name: 'Daniel Peckham', id:'1', userName:'dpeckham', isAdmin: true}
]

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
    const [theme, setTheme] = useState(themeCookie === undefined ? true : themeCookie === 'true' ? true : false);
    const [effectEnabled, toggleEffects] = useState(backgroundEffectCookie === undefined ? true : backgroundEffectCookie === 'true' ? true : false);

    // TODO - rbrunney - When refactoring the front end find a better way to properly check to if they are authenticated
    function IsAuthenticated(): boolean {
        const isAuthenticated = cookies.getCookie('DevtoolsAuthKey') !== undefined;
        return isAuthenticated
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={
                    <Login 
                        darkThemeEnabled={theme}
                        effectEnabled={effectEnabled}
                    />} 
                />
                <Route path='/signin-oidc' element={<OAuthSignIn />}/>
                <Route path='/' element={
                    <Layout 
                        darkThemeEnabled={theme}
                        effectEnabled={effectEnabled}
                        loggedInUser={users[0]}
                        toggleEffects={toggleEffects}
                        setTheme={setTheme}
                    />
                }>
                    <Route path='/dashboard' element={<Dashboard darkThemeEnabled={theme} users={users}/>} />
                    <Route path='/clientUserLookup' element={<ClientUserLookup darkThemeEnabled={theme}/>} />
                    <Route path='/clientUserPermissionsEditor' element={<ClientUserPermissionsEditor darkThemeEnabled={theme}/>} />
                    <Route path='/impersonator' element={<Impersonator darkThemeEnabled={theme} loggedInUser={users[0]}/>} />
                    {
                        users[0].isAdmin && <Route path='/adminUser' element={<AdminUser darkThemeEnabled={theme}/>}/> 
                    }
                    <Route path='/authenticatorCode' element={<AuthenticatorCode darkThemeEnabled={theme} />} />
                </Route>
            </Routes>
            {
                !IsAuthenticated() && <Navigate to="/login"/>
            }
        </BrowserRouter>
    );
}

export default Application;