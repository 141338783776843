import Card from 'react-bootstrap/Card';
import { devshackUser } from '../models';

interface IDashboardProps {
    darkThemeEnabled: boolean
    users: devshackUser[]
}

const Dashboard: React.FunctionComponent<IDashboardProps> = props => {
    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const currentYear = new Date().getFullYear();

  return (
    <div className='dashboard-card'>
        <Card className={`text-center ${cardTheme} devtool-card`} >
            <Card.Header className={textTheme}>
                <h1 className='devtool-title'>Developer Tools</h1>
                <p>Hello {props.users[0].name}</p>
                <h5>A collection of tools designed to make testing and support easier.</h5>        
            </Card.Header>        
        <Card.Body className={textTheme}>
            <Card.Text>
            <h2>Users</h2>
            <div>
                <a href='/clientUserPermissionsEditor'>Client Permissions</a>
                <p>Easily add/remove client permissions.</p>
            </div>
            <div>
                <a href='/clientUserLookup'>User List</a>
                <p>View a list of users for a selected client.</p>
            </div>
            <div>
                <a href='/authenticatorCode'>Authenticator Code</a>
                <p>Set the "Authenticator Code" (Token) for a user.</p>
            </div>
            <h2>Environments</h2>
            <a href='/impersonator'>Impersonation Token Generator</a>
            </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">© {currentYear} - devSHACK</Card.Footer>
        </Card>
    </div>
  );
}

export default Dashboard;