import { Link } from 'react-router-dom';
import BackgroundParticles from './BackgroundEffect';
import { devshack_logo } from '../../images/devshack-logo';

export interface IBackgroundProps {
    effectEnabled: boolean
    darkThemeEnabled: boolean
};

const onLogoClick = () => {
    var background = document.getElementById('background');

    background!.style.filter = 'blur()';
}

const Background: React.FunctionComponent<IBackgroundProps> = props => {
    return (
        <div id='background' className={`background ${props.darkThemeEnabled ? 'dark' : 'light'}-theme`}>
            <div>
                {props.effectEnabled && <BackgroundParticles theme={props.darkThemeEnabled}/>}
                <Link to='/dashboard' >
                    <img className="devshack-logo noselect" src={devshack_logo} alt='' onClick={onLogoClick}/>
                </Link>
            </div>
        </div>
    )
}

export default Background;
