import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import { Button, Col, Container, Dropdown, DropdownButton, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { FcPlus } from 'react-icons/fc';
import { FaTrash } from 'react-icons/fa';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { impersonatorUser, APIBaseURL, clientUser } from '../models';
import ChangesModal from './ChangesModal';

export interface IAdminUserProps {
    darkThemeEnabled: boolean
};

const AdminUser: React.FunctionComponent<IAdminUserProps> = (props) => {
    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';
    const dropdownTheme = props.darkThemeEnabled ? 'dark' : '';
    const buttonVariantTheme = props.darkThemeEnabled ? 'secondary' : 'outline-dark';
    const tableVariant = props.darkThemeEnabled ? 'dark' : '';
    const searchbarColor = props.darkThemeEnabled ? 'white' : '';
    const searchbarBackgroundColor = props.darkThemeEnabled ? '#212529' : 'transparent';
    

    const [currentError, setCurrentError] = useState('');
    const currentYear = new Date().getFullYear();
    const [isAddingNewImpersonator, setIsAddingNewImpersonator] = useState(false);
    const [isNewAdminClicked, setIsNewAdminClicked] = useState(false);
    const [isNewUserClicked, setIsNewUserClicked] = useState(false);
    const [isPermissionClicked, setIsPermissionClicked] = useState(false);
    const [impersonatorUserList, setImpersonatorUserList] = useState<impersonatorUser[] | null>();
    const [aspnetUserList, setAspnetUserList] = useState<clientUser[] | null>(null);
    const [modalText, setModalText] = useState('');
    const [confirmChanges, setConfirmChanges] = useState(false);
    const [newUser, setNewUser] = useState<clientUser | null>({userProfileId: '', userName: 'Select User', lastThenFirstName: '', isInactive: true});
    const impersonationUser = 241;
    const [currentModalMode, setCurrentModalMode] = useState('');
    const [currentImpersonator, setCurrentImpersonator] = useState<impersonatorUser | null>();
    let isLoading = !impersonatorUserList;

    const onCancelClick = () => {
        setIsAddingNewImpersonator(!isAddingNewImpersonator);
        setIsNewAdminClicked(false);
        setIsNewUserClicked(false);
    }

    const onConfirmClick = async (updatingUser: clientUser) => {
        var updateToAdminOrUser;

        if(isNewAdminClicked){
            updateToAdminOrUser = 242;
        }
        else {
            updateToAdminOrUser = impersonationUser;
        }

        try{
            axios({
                method: 'put',
                url: APIBaseURL + '/user/addUserPermission',
                params: {
                  userProfileId: updatingUser.userProfileId,
                  adminOrUser: updateToAdminOrUser
                },
                withCredentials: true
            }).then(() => {
                setIsAddingNewImpersonator(!isAddingNewImpersonator);                
            });
            
        }catch (err: any) {
            setCurrentError(err);
        }
    }

    useEffect(() => {
        async function fetchActiveUsers () {
            try{
                const response = await axios.get(APIBaseURL + '/user/activeUsers', {
                    withCredentials: true
                });
                const data = response.data.map((d: {permissionId: number, firstname: string, lastname: string, username: string, userProfileId: string}) => {
                    var impersonatorUser = {
                        permissionId: d.permissionId,
                        firstname: d.firstname,
                        lastname: d.lastname,
                        username: d.username,
                        userProfileId: d.userProfileId
                    }
                    return impersonatorUser;
                });

                setImpersonatorUserList(data);
            }catch (err: any) {
                setCurrentError(err);
            }
        }
        fetchActiveUsers();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const res = await axios.get(APIBaseURL + '/user/aspnetUsers', {
                withCredentials: true
            });
            const data = await res.data.map((d: { userProfileId: string; lastThenFirstName: string; userName: string; isInactive: boolean; }) => {
                var client: clientUser = {
                    userProfileId: d.userProfileId,
                    lastThenFirstName: d.lastThenFirstName,
                    userName: d.userName,
                    isInactive: d.isInactive
                }    
                return client;   
            });   
            setAspnetUserList(data);
        }

        fetchData();
    },[])

    const updateUserImpersonationRole = (impersontationUserId: string, newImpersonationRole: string) => {
        axios({
            method: 'put',
            url: APIBaseURL + 
            `/impersonator/updateImpersonationRole?impersonatorUserId=${impersontationUserId}&newImpersonationRole=${newImpersonationRole}`,
            withCredentials: true
        })
        .then((response) => {

        })
        .catch((e) => {
            setCurrentError(e);
        })
    }

    const deleteImpersonatorUser = (impersonationUserId: string) => {
        axios.delete(APIBaseURL + `/impersonator/deleteImpersonationUser?impersonatorUserId=${impersonationUserId}`, {
            withCredentials: true
        })
        .then((response) => {

        })
        .catch((e) => {
            setCurrentError(e);
        })
    }

    const [filterQuery, setFilterQuery] = useState("");
    const handleSearch = (value: string) => {
        setFilterQuery(value);
    }

    return (
        <div className='dashboard-card'>
            <>
                {isPermissionClicked ? (
                    <ChangesModal 
                        darkThemeEnabled={props.darkThemeEnabled}
                        title={modalText}
                        onClose={() => {
                            setIsPermissionClicked(!isPermissionClicked);
                        }}
                        confirm={() => {
                            if(currentModalMode === 'Impersonation Role') {
                                var newRole = currentImpersonator?.permissionId === impersonationUser ? "Admin" : "User"
                                updateUserImpersonationRole(currentImpersonator!.userProfileId, newRole);
                            } else if(currentModalMode === 'Delete Impersonator') {
                                deleteImpersonatorUser(currentImpersonator!.userProfileId);
                            }
                            setConfirmChanges(!confirmChanges);
                        }}
                    />
                ) : (
                    <Card className={`text-center ${cardTheme} devtool-card`}>
                        <Card.Header className={textTheme}>
                            <div>
                                <h1>Master Control</h1>
                            </div>
                        </Card.Header>
                        <Card.Body className={`${textTheme}`}>
                            <div className='newUserDiv' onClick={() => {
                                setIsAddingNewImpersonator(!isAddingNewImpersonator); 
                                setIsNewAdminClicked(false);
                                setIsNewUserClicked(false);
                                }}>
                                <FcPlus className='plusIcon'/>
                                <span className='newImpersonatorTitle'>Add New Impersonator</span>
                            </div>
                            <>
                                {isAddingNewImpersonator ? (
                                    <div className='newImpersonatorDiv'>
                                        <Button className={`buttonType-${theme}${isNewAdminClicked ? '-active' : ''}`} variant='secondary' 
                                        onClick={() => {
                                            setIsNewAdminClicked(!isNewAdminClicked);
                                            setIsNewUserClicked(false);
                                        }}
                                        >Admin</Button>
                                        <Button className={`buttonType-${theme}${isNewUserClicked ? '-active' : ''}`} variant='secondary' 
                                        onClick={() => {
                                            setIsNewUserClicked(!isNewUserClicked);
                                            setIsNewAdminClicked(false);
                                        }}
                                        >User</Button>
                                        <DropdownButton id={`dropdown-basic-button-${theme}`} className={`impersonatorDropdown-${theme}`} title={newUser?.userName} variant={buttonVariantTheme} menuVariant={dropdownTheme}>
                                            <div className='client-scroll'>
                                                <InputGroup>
                                                    <InputGroup.Text className={`filter-form-${tableVariant}`}>Search</InputGroup.Text>
                                                    <Form.Control className='client-filter' style={{ backgroundColor: searchbarBackgroundColor, color: searchbarColor}}
                                                        onChange={(e) => handleSearch(e.target.value)}/>
                                                </InputGroup>
                                                {
                                                    aspnetUserList!.filter(c => c.lastThenFirstName.toLowerCase().includes(filterQuery.toLowerCase()) || c.userName.toLowerCase().includes(filterQuery.toLowerCase()))!
                                                    .map((c, i) => (
                                                        <Dropdown.Item id={c.userName} onClick={() => {setNewUser(c)}}>{c.userName}</Dropdown.Item>
                                                    ))
                                                }
                                            </div>
                                        </DropdownButton>
                                        <MdCheckCircle className='checkIcon' onClick={() => {onConfirmClick(newUser!)}}/>
                                        <MdCancel className='cancelIcon' onClick={() => {onCancelClick()}} />
                                    </div>
                                ) : (
                                    <p></p>
                                )}
                            </>
                            <span className='title-admin-page'>Current Impersonator List</span>

                            
                            <div className='admin-page-scroll'>
                                {
                                    isLoading ? (
                                        <Spinner animation='border' className='spinner'/>
                                    ) : (
                                    <>
                                        {
                                            impersonatorUserList?.map((user) => {
                                                return (
                                                    <>
                                                    
                                                    <div className={'userDiv'}>
                                                        <Container>
                                                            <Row>
                                                                <Col className="userDiv-btn-col" xs={2} md={4} lg={6}>
                                                                    <Button className={`buttonType-${theme}-active`} variant='secondary' 
                                                                        onClick={() => {
                                                                            setCurrentModalMode('Impersonation Role');       
                                                                            setModalText(`Are you sure you want to make ${user.username} an ${user.permissionId === impersonationUser ? "Admin" : "User"}?`);
                                                                            setIsPermissionClicked(!isPermissionClicked);
                                                                            setCurrentImpersonator(user);
                                                                        }}
                                                                        >
                                                                        {user.permissionId === impersonationUser ? "User" : "Admin"}
                                                                    </Button>                                          
                                                                </Col>
                                                                
                                                                <Col className='userDiv-name-col' xs={12} md={8}>
                                                                    <span className={'nameSpan'}>{user.firstname}</span>
                                                                    <span className={'lastnameSpan'}>{user.lastname}</span>
                                                                    <span className={'usernameSpan'}>{user.username}</span>
                                                                </Col>
                                                                
                                                                <Col className='userDiv-icon-col'>
                                                                    <FaTrash className={'trashIcon'} onClick={() => {
                                                                        setCurrentModalMode('Delete Impersonator')
                                                                        setModalText(`Are you sure you want to delete ${user.username}?`);
                                                                        setIsPermissionClicked(!isPermissionClicked);
                                                                        setCurrentImpersonator(user);
                                                                    }}/>
                                                                </Col>
    
                                                            </Row>
    
                                                        </Container>
                                                    </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                    )
                                }
                            </div>                                
                        </Card.Body>
                        <Card.Footer className="text-muted card-footer">© {currentYear} - devSHACK</Card.Footer>
                    </Card>
                )}
            </>           
        </div>        
    );
}

export default AdminUser;