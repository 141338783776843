import React, { useEffect, useState } from "react";
import { Modal, Button, ModalHeader, Container, Row} from "react-bootstrap"
import {Col} from "react-bootstrap";
import { editedPermission } from "../models/clientUserPermission";

export interface ISubmitChangesModal {
    onClose: () => void
    onSubmit: () => void
    isShowing: boolean
    darkThemeEnabled: boolean
    clientUser: string
    editedPermissions: editedPermission[] | null
}

const SubmitChangesModal: React.FunctionComponent<ISubmitChangesModal> = props => {
    const modalTheme = props.darkThemeEnabled ? 'bg-dark' : 'bg-light';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';

    const hasActiveChanges = () => {
        let noActiveChange = true;

        props.editedPermissions?.forEach((perm) => {
            if(perm.grantedPermissions.length > 0 || perm.removedPermissions.length > 0) {
                noActiveChange = false;
            }
        });

        return noActiveChange;
    }

    return (
        <Modal
            className="submit-changes"           
            show={props.isShowing}
            scrollable={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            
            <ModalHeader className={`submit-changes-header-${theme}`}>
                <Modal.Title>
                Submit Permission Changes?
                </Modal.Title>
            </ModalHeader>
            
            <Modal.Body className={`${modalTheme} submit-changes-body-${theme}`}>
                <p>
                The following permission changes will be made to {props.clientUser}.
                Do you wish to continue?                           
                </p>
                <div className="edited-permissions">
                    {
                        props.editedPermissions?.map((category) => (
                            (category.grantedPermissions.length > 0 || category.removedPermissions.length > 0) &&
                            <Container key={category.category} className="edited-permissions-group">
                                
                                <span className="title">{category.category}</span>

                                <Row>
                                    {
                                        (category.grantedPermissions.length > 0) &&
                                        <Col className="edited-permissions-granted">
                                            <div className={`edited-permissions-granted-${theme}`}>
                                            {
                                                category.grantedPermissions.map((permission) => (
                                                    <div key={permission.name}> + {permission.name}</div>
                                                ))
                                            }
                                            </div>
                                        </Col>
                                    }
                                    {
                                        (category.removedPermissions.length > 0) &&
                                        <Col className="edited-permissions-removed">
                                            <div className={`edited-permissions-removed-${theme}`}>
                                            {
                                                category.removedPermissions.map((permission) => (
                                                    <div key={permission.name}> - {permission.name}</div>
                                                ))
                                            }
                                            </div>
                                        </Col>
                                    }
                                </Row>

                            </Container>
                        ))
                    }
                </div>
            </Modal.Body>                  

            <Modal.Footer className={`${modalTheme} submit-changes-footer-${theme}`}>
                <Button variant="secondary" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button className="confirm-btn" variant="secondary" onClick={props.onSubmit} disabled={hasActiveChanges()}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SubmitChangesModal
