import React from 'react';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { SiMicrosoft } from 'react-icons/si';
import Background from '../Background/Background';
import * as cookies from 'gps-utilities';
import { useNavigate } from 'react-router-dom';
import { WebBaseURL } from '../models';

export interface ILoginProps {
    darkThemeEnabled: boolean
    effectEnabled: boolean
};

const Login: React.FunctionComponent<ILoginProps> = props => {
    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const buttonTheme = props.darkThemeEnabled ? 'dark' : 'light';
    const buttonVariantTheme = props.darkThemeEnabled ? 'secondary' : 'outline-dark';
    const currentYear = new Date().getFullYear();

    let navigate = useNavigate();

    const handleLogin = () => {
        var clientId = '8dc2d39a-88f3-4348-a8b2-b20f5fd5db3d'
        var tenantId = '8f1a8af8-14f1-4431-9f7e-76f33dc9cc0f'
        var scope = 'https://gpsfx.com/aa5ea9e5-5051-40c4-aa5d-775fb0be076d/user_impersonation'
        var redirectUri = `${WebBaseURL}/signin-oidc`
        window.open(`https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`, '_self')        
    }

    // TODO - rbrunney - When refactoring the front end find a better way to properly check to if they are authenticated
    const IsAuthenticated = (): boolean => {
        var authKey = cookies.getCookie('DevtoolsAuthKey');
        return authKey !== undefined;
    }

    React.useEffect(() => {
        if (IsAuthenticated()) {
            navigate("/dashboard")
        }
    }, [])
    
    return (
        <div>
            <Background 
                darkThemeEnabled={props.darkThemeEnabled}
                effectEnabled={props.effectEnabled}
            />

            <div className='dashboard-card'>
                <Card className={`text-center ${cardTheme} devtool-card`} >
                    <Card.Header className={textTheme}>
                        <h1 className='devtool-title'>Login</h1>    
                    </Card.Header>        
                    <Card.Body className={textTheme}>
                        <Card.Text>
                        <h2>Currently Not Logged In</h2>
                            <div>
                                <Button className={`login-button-${buttonTheme}`} 
                                    variant={buttonVariantTheme} 
                                    onClick={handleLogin}>
                                    <SiMicrosoft id='microsoft-icon'/> Sign in with Microsoft
                                </Button>
                            </div>
                        </Card.Text>
                    </Card.Body>
                <Card.Footer className="text-muted">© {currentYear} - devSHACK</Card.Footer>
                </Card>
            </div>
        </div>
      );
}
export default Login;