import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-bootstrap/Fade';

export interface IErrorBannerProps {
    errorMessage: string
    closeMessage: () => void
};

const ErrorBanner: React.FunctionComponent<IErrorBannerProps> = (props) => {
    useEffect(() => {
        setTimeout(props.closeMessage, 5000);
    }, [])

    return (
        <div className='errorMessageContainer'>
            <Alert variant="danger" onClose={props.closeMessage} dismissible >
                <Alert.Heading>ERROR </Alert.Heading>
                <p>
                    {props.errorMessage}
                </p>
            </Alert>
        </div>
    );
}

export default ErrorBanner;