import React, { useEffect, useState } from 'react'
import { IconType } from 'react-icons'
import { FaChevronDown } from 'react-icons/fa'
import { editedPermission, permission } from '../models/clientUserPermission'
import { Button, Card, Form } from "react-bootstrap"
import _ from "lodash";

export interface iClientUserPermissionsProps {
    darkThemeEnabled: boolean
    title: string,
    permissions: permission[],
    icon: IconType,    
    onExpandUpdate: () => void,
    isEdited: boolean, 
    isInDefaultView?: boolean, 
    onSave: (updatedPermission: editedPermission) => void,
    onEditedchange: (changed: boolean) => void,
    updateEditMode: () => void,
    isInEditMode: boolean,
    changedPermissions: editedPermission,
};

const ClientUserPermissionsWidgetExpanded: React.FunctionComponent<iClientUserPermissionsProps> = (props) => {
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const textTheme = props.darkThemeEnabled ? 'text-dark' : 'text-light';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';
    const message = "Unsaved Changes!";

    const [borderTheme, setBorderTheme] = useState("");
    const [changeInPermissions] = useState<editedPermission>(props.changedPermissions);

    const getPermissionTitles = (permissions: permission[]) => {
        let permissionTitles = permissions.map((p) => {
            return p.name;
        });

        return permissionTitles;
    }

    const setUpCheckBoxes = () => {
        const copiedPermissionsList = _.cloneDeep(props.permissions);

        if(changeInPermissions.grantedPermissions.length > 0){
            copiedPermissionsList.forEach((permission) => {
                if(getPermissionTitles(changeInPermissions.grantedPermissions).includes(permission.name)){
                    permission.isEnabled = true;
                }
            })
        }

        if(changeInPermissions.removedPermissions.length > 0){
            copiedPermissionsList.forEach((permission) => {
                if(getPermissionTitles(changeInPermissions.removedPermissions).includes(permission.name)){
                    permission.isEnabled = false;
                }
            })
        }

        return (
            copiedPermissionsList.map((permission) => {
                return <Form.Check
                    type="checkbox"
                    id={`default-checkbox ${textTheme} ${permission.name}`}
                    key={`${permission.permissionId}`}
                    className={`checkbox-${textTheme}`}
                    label={permission.name}
                    defaultChecked={permission.isEnabled}
                    disabled={!props.isInEditMode}
                    onChange={() => {
                        onCheckChange(permission.isEnabled, permission);
                    }}
                />
            })
        );
    }

    const canBeEdited = () => {
        props.updateEditMode();
    }

    const onCheckChange = (isPermissionEnabled: boolean, permission: permission) => {
        changeInPermissions!.category = props.title;

        let activePermissions = props.permissions.filter((p) => {
            return p.isEnabled
        });

        let activePermissionsTitles = activePermissions.map((p) => {
            return p.name;
        });

        if(!activePermissionsTitles.includes(permission.name) && !isPermissionEnabled){  
            changeInPermissions.grantedPermissions.push(permission);
        } else {
            let grantedPermissionTitles: string[] = getPermissionTitles(changeInPermissions.grantedPermissions);
            if(grantedPermissionTitles.includes(permission.name)) {
                const permissionTitleIndex = grantedPermissionTitles.indexOf(permission.name);
                changeInPermissions.grantedPermissions.splice(permissionTitleIndex, 1);
            }
        }

        if(activePermissionsTitles.includes(permission.name) && isPermissionEnabled){
            changeInPermissions.removedPermissions.push(permission);
        } else {
            let removedPermissionTitles: string[] = getPermissionTitles(changeInPermissions.removedPermissions);
            if(removedPermissionTitles.includes(permission.name)) {
                const permissionTitleIndex = removedPermissionTitles.indexOf(permission.name);
                changeInPermissions.removedPermissions.splice(permissionTitleIndex, 1);
            }
        }     

        if(changeInPermissions?.grantedPermissions.length > 0 || changeInPermissions?.removedPermissions.length > 0){
            props.onEditedchange(true);
        } else {
            props.onEditedchange(false);
        }
        
        calculateActivePermissions();
    }

    const onCancelClick = () => {
        changeInPermissions.grantedPermissions.forEach((permission) => {
            const checkBox = document.getElementById(`default-checkbox ${textTheme} ${permission.name}`) as HTMLInputElement;
            checkBox.checked = false;
        })

        changeInPermissions.removedPermissions.forEach((permission) => {
            const checkBox = document.getElementById(`default-checkbox ${textTheme} ${permission.name}`) as HTMLInputElement;
            checkBox.checked = true;
        })

        changeInPermissions.grantedPermissions.splice(0);
        changeInPermissions.removedPermissions.splice(0);

        setUpCheckBoxes();
        props.onEditedchange(false);
        setBorderTheme("");
    }

    const getCurrentTotalActivePermissions = () => {
        let activePermissons = props.permissions.filter(perm => perm.isEnabled);

        let calculatedActivePermissions: number = activePermissons.length
        + changeInPermissions.grantedPermissions.length
        - changeInPermissions.removedPermissions.length;

        return calculatedActivePermissions;
    }

    const [totalActivePermissions, setTotalActivePermissions] = useState<number>(getCurrentTotalActivePermissions());
    const calculateActivePermissions = () => {
        setTotalActivePermissions(getCurrentTotalActivePermissions());
    }

    return (
        <Card id='clientPermissionsCard' className={`text-center ${cardTheme} devtool-card noselect ${borderTheme}`} >
            <Card.Header className={textTheme} onClick={() => {
                if(props.isEdited && props.isInEditMode){
                    setBorderTheme("edited-permissions");
                }
                else {
                    setBorderTheme("");
                    props.onExpandUpdate()
                }
            }
            }>
                <div className={props.isEdited ? `clientPermissionsTitle-isEdited` : `clientPermissionsTitle-notEdited-${theme}`}>
                    {React.createElement(props.icon, {className: `widgetIcon-${theme}`})}
                    <span className={`titleSpan-${theme}`}>
                        {props.title} ( {totalActivePermissions} / {props.permissions.length} )
                    </span>
                    <FaChevronDown className={`widgetChevron-${theme}`}/>
                </div>
            </Card.Header>
            <Card.Body>
                <div className='expandedBodyDiv'>
                    <>
                        {setUpCheckBoxes()}
                    </>
                </div>
                <div className='btnDiv'>
                    {props.isInEditMode ? (
                        <>
                            <span className='edit-error-message'>{borderTheme !== "" ? message : ""}</span>
                            <span className={`edit-cancel-${textTheme}`} onClick={() => {
                                onCancelClick(); 
                                calculateActivePermissions();
                                props.updateEditMode();
                                }}>
                                Cancel
                            </span> 
                            <Button className='saveButton' onClick={() => {
                                props.onSave(changeInPermissions); 
                                props.onExpandUpdate(); 
                                props.updateEditMode();
                                setBorderTheme("");
                            }}>Save</Button>                            
                        </>                                              
                    ):(
                        <></>
                    )}

                    <>
                        {
                            !props.isInDefaultView && (
                                <Button 
                                    className={props.isInEditMode ? 'editButton-true' : 'editButton-false'} 
                                    onClick={canBeEdited} 
                                    disabled={props.isInEditMode}
                                    >
                                    Edit
                                </Button>
                            )
                        }
                    </>
                </div>
            </Card.Body>            
        </Card>               
    )
}

export default ClientUserPermissionsWidgetExpanded;