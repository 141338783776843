import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';

export interface IChangesModalProps {
    darkThemeEnabled: boolean,
    title: string,
    onClose?: () => void,
    confirm: () => void,
};

const ChangesModal: React.FunctionComponent<IChangesModalProps> = (props) => {
    const textTheme = props.darkThemeEnabled ? '-light' : '';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';

    const currentYear = new Date().getFullYear();

    const updateBackground = () => {
        var background = document.getElementById('background');
        
        if(background !== undefined) {
            background!.style.filter = 'blur()';
        }

        if(props.onClose !== undefined) {
            props.onClose();
        }
    }

    useEffect(() => {
        var background = document.getElementById('background');

        if(background !== undefined) {
            background!.style.filter = 'blur(5px)';
        }
        
    }, []);

    const confirmClick = () => {
        props.confirm();
        updateBackground();
    }

    return (
        <div>
            <Card className={`text-center ${cardTheme} devtool-card`}>
                <Card.Header className={`modalHeader${textTheme}`}>
                    <div className={`modalTitle-${theme}`}>
                        <h1>Confirm Change</h1>
                        <div className='close-btn'>
                            <Button 
                                variant='secondary'
                                onClick={updateBackground}
                            >
                                <IoMdClose size={25} />
                            </Button>
                        </div>
                    </div>                   
                </Card.Header>                
                <h4 className={`modalTitle-${theme}`}>{props.title}</h4>
                <div className={'confirmBtn'}>
                    <Button
                        variant='secondary'
                        onClick={() => {confirmClick()}}
                    >
                        Confirm
                    </Button>
                    <span className={'cancelSpan'} onClick={updateBackground}>Cancel</span>
                </div>  
                <Card.Footer className="text-muted card-footer">© {currentYear} - devSHACK</Card.Footer>             
            </Card>
        </div>
    );
}

export default ChangesModal;