import React, { useEffect } from 'react'
import {useState} from 'react'
import { IconType } from 'react-icons'
import ClientUserPermissionsWidgetExpanded from './ClientUserPermissionsWidgetExpanded'
import ClientUserPermissionsWidget from './ClientUserPermissionsWidget'
import { editedPermission, permission } from '../models/clientUserPermission'

export interface iClientUserPermissionsProps {
    darkThemeEnabled: boolean,
    title: string,
    permissions: permission[],
    icon: IconType,
    onPermissionSave: (updatedPermissions: editedPermission) => void,
    changedPermissions: editedPermission[],
    isInDefaultView?: boolean
};

const ClientUserPermissionsWidgetParent: React.FunctionComponent<iClientUserPermissionsProps> = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [changeInPermissions, setChangeInPermissions] = useState<editedPermission>(
        {
            category: props.title,
            grantedPermissions: [],
            removedPermissions: []
        }
    );

    let activeChanges: boolean = changeInPermissions.grantedPermissions.length > 0 || changeInPermissions.removedPermissions.length > 0
    const [isEdited, setIsEdited] = useState(activeChanges);

    useEffect(() => {
        props.changedPermissions.forEach((category) => {
            if(category.category === props.title) {
                setChangeInPermissions({
                    category: props.title,
                    grantedPermissions: category.grantedPermissions,
                    removedPermissions: category.removedPermissions
                });
            }
        })
    }, [])

    const expandWidget = () => {
        if((!isEdited && !isInEditMode) || (!isInEditMode && isEdited)) {
            setIsExpanded(!isExpanded);
        }
    }

    const onEdit = (changed: boolean) => {
        setIsEdited(changed);
    }

    const updateEditMode = () => {
        setIsInEditMode(!isInEditMode);
    }

    return (
        <>
            {isExpanded ? (
                <ClientUserPermissionsWidgetExpanded 
                    title={props.title}
                    permissions={props.permissions}
                    icon={props.icon}
                    darkThemeEnabled={props.darkThemeEnabled}
                    onExpandUpdate={expandWidget}
                    isEdited={activeChanges}
                    onSave={props.onPermissionSave}
                    onEditedchange={onEdit}
                    updateEditMode={updateEditMode}
                    isInEditMode={isInEditMode}
                    isInDefaultView={props.isInDefaultView}
                    changedPermissions={changeInPermissions!}
                />
            ) : (
                <ClientUserPermissionsWidget 
                    title={props.title}
                    permissions={props.permissions}
                    changedPermissions={changeInPermissions!}
                    icon={props.icon}
                    darkThemeEnabled={props.darkThemeEnabled}
                    onExpandUpdate={expandWidget}
                    isEdited={activeChanges}
                />
            )}
        </>
    )
}

export default ClientUserPermissionsWidgetParent;