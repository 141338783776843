import axios from 'axios';
import * as React from 'react';
import { APIBaseURL, WebBaseURL } from '../models';
import * as cookies from 'gps-utilities';

interface IOAuthSignInProps { 
}

function OAuthSignIn(props: IOAuthSignInProps): JSX.Element {
    React.useEffect(() => {
        var searchParams = new URLSearchParams(window.location.search);
        var code = searchParams.get('code');
        if(code !== null && code !== '') {
            getOAuthToken(code)
            .then(token => {
                cookies.setCookie('DevtoolsAuthKey', token);
                window.open(`${WebBaseURL}/dashboard`, '_self')
            })
        }
    }, [])

    return (
        <></>
    );
}

async function getOAuthToken(authCode: string): Promise<string> {
    var token = '';

    await axios.get(
        `${APIBaseURL}/oauth2/token?code=${authCode}`,
    )
    .then(response => {
        token = response.data['access_token'];
    })
    .catch(e => { return ''; })

    return token
}

export default OAuthSignIn;