import React, { useEffect, useState } from 'react'
import ErrorBanner from "../Reusables/ErrorBanner"
import { Alert, Card, Spinner } from "react-bootstrap"
import { clientUserPermission, permission, editedPermission } from '../models/clientUserPermission'
import ClientUserPermissionsWidgetParent from './ClientUserPermissionsWidgetParent'
import axios from 'axios'
import { Dropdown, DropdownButton, Form, InputGroup, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { APIBaseURL, clientUser} from '../models';
import { HiOutlineSearch, HiOutlineInformationCircle } from 'react-icons/hi'
import { GPSToolTip } from 'gps-reusables';
import ClientUserLookup from "../ClientUserLookup/ClientUserLookup";
import SubmitChangesModal from "./SubmitChangesModal";
import { AiOutlineUser, AiOutlineUsergroupAdd, AiOutlineCalendar } from 'react-icons/ai';
import { FaHandshake, FaClipboardCheck, FaCommentDots, FaPiggyBank, FaExchangeAlt, FaCreditCard, 
FaBalanceScale, FaRoute, FaFileImport, FaFileContract, FaFileUpload, FaToolbox } from 'react-icons/fa';
import { IconType } from "react-icons/lib"
import { RiShieldKeyholeFill } from 'react-icons/ri';

export interface IClientUserPermissionsEditorProps {
    db?: string
    darkThemeEnabled: boolean
};

const ClientUserPermissionsEditor: React.FunctionComponent<IClientUserPermissionsEditorProps> = props => {
    const buttonOutline = props.darkThemeEnabled ? 'light' : 'secondary';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';
    const buttonVariantTheme = props.darkThemeEnabled ? 'secondary' : 'outline-dark';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const tableVariant = props.darkThemeEnabled ? 'dark' : '';
    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const [currentError, setCurrentError] = useState('');

    const usernameLookupColor = props.darkThemeEnabled ? 'white' : '';
    const usernameLookupBackgroundColor = props.darkThemeEnabled ? '#212529' : 'transparent';
    const currentYear = new Date().getFullYear();

    let dbs: string[] = [];
    const [clientUser, setCurrentClientUser] = useState<clientUser>({lastThenFirstName: "", userName: "", isInactive: true, userProfileId: ""});
    const [currentUserEditingName, setUserEditingName] = useState("");
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [clientUserPermissions, setClientUserPermissions] = useState<clientUserPermission[] | null>(null)
    const [changedPermissions, setChangedPermissions] = useState<editedPermission[] | null>(null);
    const [filteredPermissions, setFilteredPermissions] = useState<clientUserPermission[] | null>(clientUserPermissions)
    const [isInDefaultView, setIsInDefaultView] = useState<boolean>(true);
    let isLoading: boolean = !clientUserPermissions;

    const getPermissionIcon = (value: string) => {
        switch(value){
            case 'Admin':
                return RiShieldKeyholeFill;
            case 'Balance Reporting':
                return FaBalanceScale;
            case 'Balance Sheet Hedging':
                return FaCreditCard;
            case 'Beneficiaries':
                return FaFileImport;
            case 'Cash Flow Hedging':
                return FaRoute;
            case 'Clients':
                return AiOutlineUsergroupAdd
            case 'Contract Upload':
                return FaFileUpload;
            case 'Contracts':
                return FaFileContract;
            case 'Forecasting':
                return AiOutlineCalendar;
            case 'IC Netting':
                return FaHandshake;
            case 'Instruction Batch Upload':
                return FaClipboardCheck;
            case 'Instructions':
                return FaCommentDots;
            case 'MCA':
                return FaPiggyBank;
            case 'Netting':
                return FaHandshake;
            case 'old bsh':
                return FaCreditCard;
            case 'Reports':
                return FaRoute;
            case 'Swaps':
                return FaExchangeAlt;
            case 'TreasuryManagement':
                return FaToolbox;
            case 'Users':
                return AiOutlineUsergroupAdd;
            default:
                return AiOutlineUser;
        }
    }

    useEffect(() => {
        axios.get(APIBaseURL + `/clientUserPermission/defaultPermissions`, {
            withCredentials: true
        })
        .then((response) => {
            var data = response.data
            var permisisonGroups = Object.keys(data);
            var retrievedPermissions: clientUserPermission[] = [];

            let editedPermissionsList: editedPermission[] = [];

            permisisonGroups.forEach(permissionCategory => {
                retrievedPermissions.push(
                    {
                        title: permissionCategory,
                        permissions: data[permissionCategory],
                        icon: getPermissionIcon(permissionCategory)
                    }
                );

                editedPermissionsList.push(
                    {
                        category: permissionCategory,
                        grantedPermissions: [],
                        removedPermissions: []
                    }
                );

                setClientUserPermissions(retrievedPermissions);
                setChangedPermissions(editedPermissionsList);
            });
        });
    }, [])

    const [filter, setFilter] = useState<string>();
    useEffect(() => {
        if(filter !== undefined && clientUserPermissions) {
            const filteredData = clientUserPermissions!.filter(permission => {
                if(permission.title.toLowerCase().includes(filter!.toLowerCase())) {
                    return permission;
                }
            })
    
            setFilteredPermissions(filteredData);
        }
    }, [filter]);

    const createPermissonCategories = (permissions :clientUserPermission[]) => {
        
        let firstColumn: clientUserPermission[] = []
        let secondColumn: clientUserPermission[] =[]
        permissions?.forEach((permission, i) => {
            if( i <= Math.floor(permissions.length / 2)) {
                firstColumn.push(permission)
            } else {
                secondColumn.push(permission)
            }
        })

        const checkPermissionNames = (permissionNames: permission[]) => {
            const hasNoSpaceRegex = /^[^\s]*$/gm;
            const addSpaceRegex = /([A-Z][a-z]*)/g;

            permissionNames.forEach((perm) => {
                if(perm.name.match(hasNoSpaceRegex)) {
                    let result = '';
                    perm.name.match(addSpaceRegex)?.forEach(group => {
                        if(group.length > 1) {
                            result += ` ${group} `
                        } else {
                            result += `${group}`
                        }
                    });
                    perm.name = result;
                }
            })
        }

        return (
            <div className='permission-row'>
                <div className='permission-column'>
                    <>
                        {
                            firstColumn.map((permission) => {
                                checkPermissionNames(permission.permissions);
                                return (
                                    <ClientUserPermissionsWidgetParent 
                                        key={permission.title}
                                        darkThemeEnabled={props.darkThemeEnabled} 
                                        title={permission.title} 
                                        permissions={permission.permissions} 
                                        icon={getPermissionIcon(permission.title)} 
                                        isInDefaultView={isInDefaultView}
                                        onPermissionSave={changePermissions}
                                        changedPermissions={changedPermissions!}                      
                                    />
                                )
                            })
                        }
                    </>
                </div>
                <div className='permission-column'>
                    <>
                        {
                            secondColumn.map((permission) => {
                                checkPermissionNames(permission.permissions);
                                return (
                                    <ClientUserPermissionsWidgetParent 
                                        key={permission.title}
                                        darkThemeEnabled={props.darkThemeEnabled} 
                                        title={permission.title} 
                                        permissions={permission.permissions} 
                                        icon={getPermissionIcon(permission.title)}
                                        isInDefaultView={isInDefaultView}
                                        onPermissionSave={changePermissions}
                                        changedPermissions={changedPermissions!}                        
                                    />
                                )
                            })
                        }
                    </>
                </div>
            </div>
        )
    }

    function validate() {
        if(db != 'Select Database' && clientUser?.userName != ''){
            return false;
        }else {
            return true;
        }
    }
    
    const [db, setDb] = useState('Select Database');
    const [databases, setDbs] = useState(dbs);
    useEffect(() => {
        axios.get(APIBaseURL + '/home/dbNames', {
            withCredentials: true
        })
        .then((response) => {
            setDbs(response.data);
        })
        .catch((e) => { setCurrentError(e); });
    }, []);
    
    function createToolTipText(tooltipContent: string) {
        return <div className={`tool-tip-block-${theme}`}>
            {tooltipContent}
            <span className='tool-tip-tip'></span>
        </div>
    }
    
    function handleUserChange(clientUserName: string) {
        let clientUser = {
            lastThenFirstName: "",
            userProfileId: "",
            userName: clientUserName,
            isInactive: false
        }

        setCurrentClientUser(clientUser);
    };
        
    function handlePermissionChanges() {

        let grantedPermissionIds: number[] = [];
        let removedPermissionIds: number[] = [];

        changedPermissions?.forEach((changedPerm) => {
            changedPerm.grantedPermissions.forEach((grantedPerm) => {
                grantedPermissionIds.push(parseInt(grantedPerm.permissionId));
            })

            changedPerm.removedPermissions.forEach((removedPerm) => {
                removedPermissionIds.push(parseInt(removedPerm.permissionId));
            })
        })


        let employeeId: string = 'BDD87A6B-C419-4C7A-8E3F-AB3BBA3B53EC'
        axios({
            method: 'put',
            url: APIBaseURL + `/clientUserPermission?employeeUserId=${employeeId}`,
            data: {
                "clientUserId" : clientUser.userProfileId,
                "grantedPermissions" : grantedPermissionIds,
                "removedPermissions" : removedPermissionIds
            },
            withCredentials: true
        })
        .then((response) => {
            setShowSubmitModal(false);
            submitChangesMessage();
            fetchPermissions();
        })
        .catch((e) => {
            setCurrentError(e);
        })
    }

    async function fetchPermissions() {
        setFilteredPermissions(null);
        setClientUserPermissions(null);

        await axios.get(APIBaseURL + `/clientUserPermission?clientUserUsername=${clientUser?.userName}`, {
            withCredentials: true
        })
        .then((response) => {
            var data = response.data
            var permisisonGroups = Object.keys(data);
            var retrievedPermissions: clientUserPermission[] = [];

            let editedPermissionsList: editedPermission[] = [];

            permisisonGroups.forEach(permissionCategory => {
                retrievedPermissions.push(
                    {
                        title: permissionCategory,
                        permissions: data[permissionCategory],
                        icon: AiOutlineUser
                    }
                );

                editedPermissionsList.push(
                    {
                        category: permissionCategory,
                        grantedPermissions: [],
                        removedPermissions: []
                    }
                );

                setClientUserPermissions(retrievedPermissions);
                setChangedPermissions(editedPermissionsList);
                setUserEditingName(clientUser.userName);
            });

            setIsInDefaultView(false);
        })
        .catch((e) => { setCurrentError(e + ". Could not find permissions. Please check you have the right username") });
    }

    function enterKeyPressed(event: React.KeyboardEvent) {
        if (event.key === 'Enter'){
            fetchPermissions();
        }
    };

    const updateCurrentClientUser = (currentClientUser: clientUser) => {
        setCurrentClientUser(currentClientUser);
    }

    const changePermissions = (updatedPermissions: editedPermission) => {
        if(changedPermissions != null) {
            changedPermissions.map((permission) =>{
                if(permission.category === updatedPermissions.category){
                    permission.grantedPermissions = updatedPermissions.grantedPermissions;
                    permission.removedPermissions = updatedPermissions.removedPermissions;
                }
            })

            setChangedPermissions(changedPermissions)
        }
    }

    const [showSubmitMessage, setShowSubmitMessage] = useState(false);
    function submitChangesMessage() {
        setShowSubmitMessage(true);
        setTimeout(() => setShowSubmitMessage(false), 2000);
    }

    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const handleClose = () => {
        setShowSubmitModal(false);
    }

    const handleShow = () => {
        setShowSubmitModal(true);
    }

    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
    useEffect(() => {
        setSubmitDisabled(clientUser.userName == '');
    }, [clientUser])

    return (
        <div>
            <div className='error-banner-placement'>
                {
                    currentError !== '' 
                    ? <ErrorBanner 
                        errorMessage={currentError.toString()} 
                        closeMessage={() => {setCurrentError('')}}
                      /> : <p></p>
                }
            </div>
            <>
                {
                    isSearchClicked ? (
                        <ClientUserLookup
                            isModal={isSearchClicked}
                            onClose={() => { setIsSearchClicked(!isSearchClicked) }}
                            onConfirm={ updateCurrentClientUser }
                            db={db}
                            darkThemeEnabled={props.darkThemeEnabled}
                        />
                    ) : (                        
                        <div className='dashboard-card'>
                            <SubmitChangesModal
                                onClose={handleClose}
                                onSubmit={handlePermissionChanges}
                                isShowing={showSubmitModal}
                                darkThemeEnabled={props.darkThemeEnabled}
                                clientUser={clientUser!.userName}
                                editedPermissions={changedPermissions}
                            />

                            <Card className={`text-center ${cardTheme} devtool-card-user-editor`} >
                                <Card.Header className={textTheme}>
                                    <h1 className="devtool-title">Client User Permissions Editor</h1>      
                                </Card.Header>  
                                <Card.Body className={`cul-card ${textTheme}`}>
                                    <div className='cupe-db-dropdown'>
                                        <span className='title'>Database Name</span>
                                        <DropdownButton id={`dropdown-basic-button-${theme}`} title={props.db|| db} variant={buttonVariantTheme} menuVariant={tableVariant}>
                                            {
                                                databases.map((db) => (
                                                    <Dropdown.Item key={db} onClick={() => setDb(db)}>{db}</Dropdown.Item>
                                                ))
                                            }
                                        </DropdownButton>
                                    </div>
                                    <div className='cupe-username-search'>
                                        <div className="username-title">
                                            <span className='title'>Username</span>
                                            <GPSToolTip 
                                                className='username-icon' 
                                                content={createToolTipText("Username of user's permissions you want to edit")} 
                                                direction='up'
                                                >
                                                <HiOutlineInformationCircle/>
                                            </GPSToolTip>
                                        </div>
                                        <InputGroup className="mb-3">
                                            <Button className={`lookup-btn input-group-prepend ${cardTheme} btn-outline-${buttonOutline}`} onClick={() => {setIsSearchClicked(!isSearchClicked)}}>
                                                <HiOutlineSearch className="lookup-icon" size={25}/>
                                            </Button>   
                                            <Form.Control className={`username-lookup-form-${theme}`}
                                                style={{backgroundColor: usernameLookupBackgroundColor, color: usernameLookupColor}}
                                                id="basic-url" aria-describedby="basic-addon3" placeholder='Enter Username...'
                                                value={clientUser === null ? '' : clientUser?.userName}
                                                onChange={(e) => { handleUserChange(e.target.value); }}
                                                onKeyPress={(e) => {enterKeyPressed(e);}}
                                            />
                                            <Button className={`cupe-search-btn button-${theme}`} id="button-addon2"
                                                onClick={fetchPermissions} 
                                                disabled={validate()}
                                                variant={buttonVariantTheme}>
                                            Search
                                        </Button>
                                        </InputGroup>
                                    </div>
                                    <div className="permissions-filter">
                                        <span className='title'>Permissions Filter</span>
                                        <InputGroup className="mb-3">
                                            <Form.Control className={`permissions-filter-form-${theme}`}
                                                style={{backgroundColor: usernameLookupBackgroundColor, color: usernameLookupColor}}
                                                id="basic-url permission-filter" aria-describedby="basic-addon3"   
                                                onChange={(e) => {
                                                    setFilter(e.target.value)
                                                }}
                                                value={filter}
                                            />
                                            <Button className={`cupe-search-btn button-${theme}`} id="button-addon2 filter-close"
                                                    onClick={() => {
                                                        setFilter('')
                                                    }} 
                                                    disabled={filter === undefined || filter === ''}
                                                    variant={buttonVariantTheme}>
                                                X
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Body>
                                <Card.Body className={`cul-card ${textTheme}`}>                                    
                                    <>
                                        {
                                           (currentUserEditingName != '' && !isInDefaultView) && 
                                            <div className="current-user-editing">
                                                Editing {currentUserEditingName}'s permissions
                                            </div>
                                           
                                        }
                                    </>
                                    <Alert className='permissions-saved-message' show={showSubmitMessage} variant='success'>
                                            Changes saved
                                    </Alert>
                                    <div className="clear-changes-btn">
                                        <Link className={`clear-changes-link-${theme}`} to={''} onClick={() => fetchPermissions()}>Clear All Changes</Link>
                                    </div>
                                </Card.Body>
                                <Card.Body>
                                    <div className='permission-scroll'>
                                        <>
                                            {
                                                isLoading ? (<Spinner animation='border' className='spinner'/>)
                                                : (filteredPermissions != null ? createPermissonCategories(filteredPermissions!) : createPermissonCategories(clientUserPermissions!))
                                            }
                                        </>
                                    </div>
                                    <div className="cupe-submit">
                                        <Button 
                                            id="cupe-submit-btn" 
                                            className="confirm-btn" 
                                            onClick={handleShow} 
                                            disabled={isSubmitDisabled}
                                            >
                                            Submit
                                        </Button>
                                    </div> 
                                </Card.Body>
                                <Card.Footer className="text-muted">© {currentYear} - devSHACK</Card.Footer>
                            </Card>
                        </div>                                         
                    )
                }                
            </>
        </div>        
    )
}

export default ClientUserPermissionsEditor;