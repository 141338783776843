import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form, InputGroup, Button, Spinner, Table, ButtonProps, ButtonGroup } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { APIBaseURL, clientUser, devshackUser, impersonationUser, impersonatedUser, recentImpersonatedUser } from '../models';
import { GPSToolTip } from 'gps-reusables';
import { useCopyToClipboard } from 'usehooks-ts';
import { HiOutlineSearch, HiOutlineInformationCircle } from 'react-icons/hi'
import {GrCopy} from 'react-icons/gr';
import axios from 'axios';
import ErrorBanner from '../Reusables/ErrorBanner';
import ClientUserLookup from '../ClientUserLookup/ClientUserLookup';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/esm/helpers';
import { type } from '@testing-library/user-event/dist/type';

export interface IImpersonatorProps {
    darkThemeEnabled: boolean
    loggedInUser: devshackUser
};

const dbNames: string[] = [];

const Impersonator: React.FunctionComponent<IImpersonatorProps> = props => {
    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const tableVariant = props.darkThemeEnabled ? 'dark' : '';

    function createToolTipText(tooltipContent: string) {
        return <div className={`tool-tip-block-${theme}`}>
            {tooltipContent}
            <span className='tool-tip-tip'></span>
        </div>
    }

    const [impersonatorToken, setImpersonatorToken] = useState("");
    let hasToken = !impersonatorToken;

    const [value, copy] = useCopyToClipboard();
    const [showCopyMessage, setCopyMessage] = useState('');
    const [dispayCopiedMessage, setDisplayCopyMsg] = useState(false);

    const displayCopyMessage = (val: string) => {
        setCopyMessage(val);
        setDisplayCopyMsg(true);
    }
    const dropdownTheme = props.darkThemeEnabled ? 'dark' : '';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';
    const buttonVariantTheme = props.darkThemeEnabled ? 'secondary' : 'outline-dark';
    const buttonOutline = props.darkThemeEnabled ? 'light' : 'secondary';
    const usernameLookupColor = props.darkThemeEnabled ? 'white' : '';
    const usernameLookupBackgroundColor = props.darkThemeEnabled ? '#212529' : 'transparent';

    const [databaseNames, setDatabaseNames] = useState(dbNames);
    const [db, setDb] = useState('Select Database');
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [currentClientUser, setCurrentClientUser] = useState<clientUser>();
    const [impersonationUser, setImpersonationUser] = useState<impersonationUser | null>(null);
    const [impersonatedUser, setImpersonatedUser] = useState<impersonatedUser | null>(null);
    const currentYear = new Date().getFullYear();
    const [recentUsers, setRecentUsers] = useState<recentImpersonatedUser[] | null>([{name: '', id: 0, date: ''}]);
    const [numberofRecentUsers, setNumberOfRecentUsers] = useState<number>(0);
    let createdByUser = "GPS\\dpeckham";

    function validate() {
        if(db != 'Select Database' && impersonationUser && impersonatedUser){
            return false;
        }else {
            return true;
        }
    }

    const [currentError, setCurrentError] = useState('');
    useEffect(() => {
        axios.get(APIBaseURL + '/home/dbNames', {
            withCredentials: true
        })
        .then((response) => {
            setDatabaseNames(response.data);
        })
        .catch((e) => { setCurrentError(e) });

        if(!props.loggedInUser.isAdmin) {
            const filteredDatabaseNames = databaseNames.filter(name => name.toLowerCase() !== 'production');
            setDatabaseNames(filteredDatabaseNames);
        }
    }, []);

    const [impersonationUsers, setImpersonationUsers] = useState<impersonationUser[] | null>(null);
    let isLoading = !impersonationUsers;
    useEffect(() => {
        axios.get(APIBaseURL + "/impersonator/impersonationUsers", {
            withCredentials: true
        })
        .then(async (response) => {
            var data = response.data;            
            setImpersonationUsers(data); 
        })
        .catch((err) => {
            setCurrentError(err + '. Failed to get impersonators');
        });        
    }, [])

    function handleImpersonatedChange(impersonatedUserName: string) {
        let updatedImpersonatedUser = {
            username: impersonatedUserName,
            id: ""
        }

        setImpersonatedUser(updatedImpersonatedUser);
    };

    async function fetchImpersonationToken() {
        
        await axios.get(APIBaseURL + '/Impersonator', {
            params: {
                databaseName: db,
                targetUsername: impersonationUser!.name,
                impersonatedUserProfileId: impersonatedUser?.id,
                impersonatedUsername: impersonatedUser?.username,
                createdBy: createdByUser,
                isAdmin: props.loggedInUser.isAdmin
            },
            withCredentials: true
        }).then((res) => {
            setImpersonatorToken(res.data);
        }).catch((err) => {
            setCurrentError(err);
        });        
        
        displayCopyMessage(impersonatorToken);
    };

    function enterKeyPressed(event: React.KeyboardEvent) {
        if (event.key === 'Enter' && (db != 'Select Database' && impersonationUser && impersonatedUser)){
            fetchImpersonationToken();
        }
    };

    const updateCurrentClientUser = (currentClientUser: clientUser) => {
        setCurrentClientUser(currentClientUser);
    }

    useEffect(() => {
        try {
            setImpersonatedUser({id:"", username:currentClientUser!.userName})
        } catch {

        }
    }, [currentClientUser]) 
    
    useEffect(() => {
        async function fetchRecentUsers () {
            try{
                const response = await axios.get(APIBaseURL + '/impersonator/recentImpersonatedUsers', {
                    params: {employeeUsername: createdByUser,
                    totalImpersonatedUsers: numberofRecentUsers
                    },
                    withCredentials: true
                });
                const data = response.data.map((d: {name: string, id: number, date: string}) => {
                    var recentImpersonation = {
                        name: d.name,
                        id: d.id,
                        date: d.date
                    }
                    return recentImpersonation;
                });
                setRecentUsers(data);
            }catch (err: any) {
                setCurrentError(err);
            }
        }
        fetchRecentUsers();
    }, [numberofRecentUsers])

    return (
        <div>
            <div className='error-banner-placement'>
                {
                    currentError !== '' 
                    ? <ErrorBanner 
                        errorMessage={currentError.toString()} 
                        closeMessage={() => {setCurrentError('')}}
                      /> : <p></p>
                }
            </div>
            
            <>
                {
                    isSearchClicked ? (
                        <ClientUserLookup
                            isModal={isSearchClicked}
                            onClose={() => { setIsSearchClicked(!isSearchClicked) }}
                            onConfirm={ updateCurrentClientUser }
                            db={db}
                            darkThemeEnabled={props.darkThemeEnabled}
                        />
                    ) : (
                        <div className='dashboard-card'>
                            <Card className={`text-center ${cardTheme} devtool-card`} >
                                <Card.Header className={textTheme}>
                                    <h1 className="devtool-title">Impersonation Token Generator</h1>
                                    <p>You are logged in as {props.loggedInUser.userName}</p>
                                    <p>To impersonate a user you can lookup their username using Users and enter it below.</p>        
                                </Card.Header> 
                                <Card.Body className={`${textTheme}`}>
                                    <div className='impersonator-recent'>
                                        <span className='title'>Recent Impersonated Users</span>
                                        <GPSToolTip className='impersonator-icon' content={createToolTipText("Past users you've impersonated.")} direction='up'>
                                            <HiOutlineInformationCircle/>
                                        </GPSToolTip>
                                    </div>
                                    <div>
                                        <Table striped bordered hover variant={tableVariant}>
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Last Impersonated</th>
                                                </tr>                                                
                                            </thead>
                                            <tbody>
                                                {
                                                    recentUsers!.map((user) => {
                                                        return (
                                                            <tr style={{ cursor: 'pointer', overflow: 'auto'}} 
                                                                onClick={() => setImpersonatedUser({username: user.name, id: user.id.toString()})}
                                                            >
                                                                <td>{user.name}</td>
                                                                <td>{user.date}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }                                                          
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div>
                                        <Button 
                                            className={`recentOption-${numberofRecentUsers === 5 ? 'active' : `notActive-${theme}`}`} 
                                            onClick={() => { 
                                                setNumberOfRecentUsers(5);
                                            }}
                                            variant='secondary'
                                        >5</Button>
                                        <Button 
                                            className={`recentOption-${numberofRecentUsers === 10 ? 'active' : `notActive-${theme}`}`} 
                                            onClick={() => {
                                                setNumberOfRecentUsers(10);
                                            }}
                                            variant='secondary'
                                        >10</Button>
                                        <Button 
                                            className={`recentOption-${numberofRecentUsers === 15 ? 'active' : `notActive-${theme}`}`} 
                                            onClick={() => {
                                                setNumberOfRecentUsers(15);
                                            }}
                                            variant='secondary'
                                        >15</Button>
                                    </div>
                                </Card.Body>       
                                <Card.Body className={`cul-card ${textTheme}`}>
                                    <div className='cul-db-dropdown impersonator-sub-title'>
                                        <br/>
                                        <span className='title'>Database Name</span>
                                        <DropdownButton id={`dropdown-basic-button-${theme}`} title={db} variant={buttonVariantTheme} menuVariant={dropdownTheme}>
                                            <div className='client-scroll'>
                                                { 
                                                    databaseNames.map((db, i) => (
                                                        <Dropdown.Item onClick={() => { setDb(db); }}>{db}</Dropdown.Item>
                                                    ))
                                                }
                                            </div>
                                        </DropdownButton>
                                    </div>

                                    <div className='cul-dropdown impersonator-sub-title'>
                                        <br/>
                                        <div className='impersonator-title'>
                                            <span className='title'>Impersonator</span>
                                            <GPSToolTip className='impersonator-icon' content={createToolTipText("Usually you - The person logging in.")} direction='up'>
                                                <HiOutlineInformationCircle/>
                                            </GPSToolTip>
                                        </div>
                                        <DropdownButton 
                                            className='dropdown-position' 
                                            id={`dropdown-basic-button-${theme}`} 
                                            title={
                                                impersonationUser !== null ? impersonationUser.name : 'Select Impersonator'
                                            } 
                                            variant={buttonVariantTheme} 
                                            menuVariant={dropdownTheme}
                                            >
                                            <div className='client-scroll'>
                                                {
                                                    isLoading ? (
                                                        <Spinner animation='border' className='spinner-small'/>
                                                    ) : impersonationUsers?.map((u, i) => (
                                                        <Dropdown.Item onClick={() => setImpersonationUser(u)}>{u.name}</Dropdown.Item>
                                                    ))
                                                }
                                            </div>
                                        </DropdownButton>
                                    </div>            
                                </Card.Body>
                                <Card.Body className={`cul-card ${textTheme}`}>
                                    <div className='username-lookup-card'>
                                        <div className='impersonated-title'>
                                            <span className='title'>Impersonated</span>
                                            <GPSToolTip className='impersonated-tooltip' content={createToolTipText("Person to Impersonate.")} direction='up'>
                                                <HiOutlineInformationCircle className='info-icon'/>
                                            </GPSToolTip>
                                        </div>
                                        <br/>
                                        <InputGroup className="mb-3">
                                            <GPSToolTip content={createToolTipText("Leads to client user look up.")} direction='up'> 

                                            <Button className={`lookup-btn input-group-prepend ${cardTheme} btn-outline-${buttonOutline}`} onClick={() => {setIsSearchClicked(!isSearchClicked)}}>
                                                <HiOutlineSearch className="lookup-icon" size={25}/>
                                            </Button>                         
                                        </GPSToolTip>
                                        <Form.Control className={`username-lookup-form-${theme}`}
                                            style={{backgroundColor: usernameLookupBackgroundColor, color: usernameLookupColor}}
                                            id="basic-url" aria-describedby="basic-addon3" placeholder='Enter Impersonated Username...'
                                            value={currentClientUser === null ? '' : impersonatedUser?.username}
                                            onChange={(e) => { handleImpersonatedChange(e.target.value) }}
                                            onKeyPress={(e) => {enterKeyPressed(e);}}
                                        />

                                        </InputGroup>                
                                    </div>                
                                </Card.Body>
                                <Card.Body>
                                <div>
                                    <Button className={`buttonGenerateToken-${theme}`} onClick={(() => fetchImpersonationToken())} disabled={validate()} variant='secondary' >Generate Token</Button>
                                </div> 
                                </Card.Body>
                                <Card.Body className={`cul-card ${textTheme}`}>
                                    <>
                                        {hasToken ? (
                                            <div className='impersonationToken-div'>
                                                <span >No token to show</span>
                                            </div>
                                        ):(
                                            <div>
                                                <div>
                                                    <Table striped bordered hover variant={tableVariant}>
                                                        <thead>
                                                            <tr>
                                                                <th>Impersonation Token</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='token-cell'>
                                                                    <div className='token-data'>
                                                                        <div className='impersonator-token'>
                                                                            {impersonatorToken}
                                                                        </div>
                                                                        <div className='copy-button'>
                                                                            <Button onClick={() => {copy(impersonatorToken); displayCopyMessage(impersonatorToken)}}>
                                                                                <GrCopy size={25}/>
                                                                            </Button>
                                                                        </div>
                                                                    </div>                                                      
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className='copy-msg' >
                                                    {dispayCopiedMessage && <span className="copy-msg" >{`Copied to clipboard!`}</span>}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </Card.Body>
                                <Card.Footer className="text-muted">© {currentYear} - devSHACK</Card.Footer>
                            </Card>
                        </div>
                    )
                }
            </>
        </div>
    )
}

export default Impersonator;

function styled(Button: BsPrefixRefForwardingComponent<"button", ButtonProps>) {
    throw new Error('Function not implemented.');
}
