import React, { useEffect, useState } from 'react';
import ErrorBanner from '../Reusables/ErrorBanner';
import { APIBaseURL, clientUser } from '../models';
import ClientUserLookup from '../ClientUserLookup/ClientUserLookup';
import { Button, Card, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { GPSToolTip } from 'gps-reusables';
import { HiOutlineInformationCircle, HiOutlineSearch } from 'react-icons/hi';

export interface IAuthenticatorCodeProps {
    darkThemeEnabled: boolean
}

const AuthenticatorCode: React.FunctionComponent<IAuthenticatorCodeProps> = props => {

    const textTheme = props.darkThemeEnabled ? 'text-light' : '';
    const cardTheme = props.darkThemeEnabled ? 'bg-dark' : '';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';
    const buttonVariantTheme = props.darkThemeEnabled ? 'secondary' : 'outline-dark';
    const buttonOutline = props.darkThemeEnabled ? 'light' : 'secondary';
    const tableVariant = props.darkThemeEnabled ? 'dark' : '';
    const usernameLookupBackgroundColor = props.darkThemeEnabled ? '#212529' : 'transparent';
    const usernameLookupColor = props.darkThemeEnabled ? 'white' : '';

    let dbs: string[] = [];
    const [db, setDb] = useState('Select Database');
    const [databases, setDbs] = useState(dbs);
    const [currentClientUser, setCurrentClientUser] = useState<clientUser>();
    const [authCode, setAuthCode] = useState<number>();

    const [saveMessage, setSaveMessage] = useState('')
    const [currentError, setCurrentError] = useState('');
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const currentYear = new Date().getFullYear();

    const updateClientUser = (currentClientUser: clientUser) => {
        setCurrentClientUser(currentClientUser);
    }

    const  createToolTipText = (tooltipContent: string) => {
        return <div className={`tool-tip-block-${theme}`}>
            {tooltipContent}
            <span className='tool-tip-tip'></span>
        </div>
    }

    const handleUserChange = (clientUserName: string) => {
        let clientUser = {
            lastThenFirstName: "",
            userProfileId: "",
            userName: clientUserName,
            isInactive: false
        }

        setCurrentClientUser(clientUser);
    };

    const handleAuthCodeChange = (authCodeInput: string) => {
        let isValidAuthCode :boolean = /^[0-9]*$/.test(authCodeInput);

        if(isValidAuthCode && authCodeInput !== '') {
            setAuthCode(parseInt(authCodeInput));
        } else {
            setAuthCode(undefined);
        }
    };

    const isSaveButtonDisabled = (): boolean => {
        return db === 'Select Database' || currentClientUser === undefined || authCode === undefined;
    }

    const saveAuthenticatorCode = () => {
        axios({
            method: 'put',
            url: APIBaseURL + `/impersonator/authenticatorCode/?clientUserUsername=${currentClientUser?.userName}&newAuthCode=${authCode}`,
            withCredentials: true
        })
        .then((response) => {
            setSaveMessage(response.data)
        })
        .catch((e) => {
            setCurrentError(e);
        })
    }

    useEffect(() => {
        axios.get(APIBaseURL + '/home/dbNames', {
            withCredentials: true
        })
        .then((response) => {
            setDbs(response.data);
        })
        .catch((e) => { setCurrentError(e); });
    }, []);

    return (
        <div>
            <div className='error-banner-placement'>
                {
                    currentError !== '' &&
                    <ErrorBanner 
                        errorMessage={currentError.toString()} 
                        closeMessage={() => {setCurrentError('')}}
                    />
                }
            </div>

            <>
                {
                    isSearchClicked ? (
                        <ClientUserLookup 
                            darkThemeEnabled={props.darkThemeEnabled}  
                            db={db}
                            isModal={isSearchClicked}
                            onClose={() => { setIsSearchClicked(!isSearchClicked) }}
                            onConfirm={ updateClientUser }                    
                        />
                    )
                    : (
                        <div className='dashboard-card'>
                            <Card className={`text-center ${cardTheme} devtool-card`}>
                                <Card.Header className={textTheme}>
                                    <h1 className='devtool-title'>Authenticator Code</h1>
                                </Card.Header>
                                <Card.Body className={`cul-card ${textTheme}`}>
                                    <div className='cupe-db-dropdown'>
                                        <span className='title'>Database Name</span>
                                        <DropdownButton 
                                            id={`dropdown-basic-button-${theme}`} 
                                            title={db} 
                                            variant={buttonVariantTheme} 
                                            menuVariant={tableVariant}
                                            >
                                            {
                                                databases.map((db) => (
                                                    <Dropdown.Item key={db} onClick={() => setDb(db)}>{db}</Dropdown.Item>
                                                ))
                                            }
                                        </DropdownButton>
                                    </div>
                                    <div className='cupe-username-search-auth'>
                                        <div className="username-title">
                                            <span className='title'>Username</span>
                                            <GPSToolTip 
                                                className='username-icon' 
                                                content={createToolTipText("Username of the user you want to add an authentication code for!")} 
                                                direction='up'
                                                >
                                                <HiOutlineInformationCircle/>
                                            </GPSToolTip>
                                        </div>
                                        <InputGroup className="mb-3">
                                            <Button className={`lookup-btn input-group-prepend ${cardTheme} btn-outline-${buttonOutline}`} onClick={() => {setIsSearchClicked(!isSearchClicked)}}>
                                                <HiOutlineSearch className="lookup-icon" size={25}/>
                                            </Button>   
                                            <Form.Control className={`username-lookup-form-${theme}`}
                                                style={{backgroundColor: usernameLookupBackgroundColor, color: usernameLookupColor}}
                                                id="basic-url" aria-describedby="basic-addon3" placeholder='Enter Username...'
                                                value={currentClientUser?.userName}
                                                onChange={(e) => { handleUserChange(e.target.value); }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div>
                                        <div className="username-title">
                                            <span className='title'>Auth Code</span>
                                            <GPSToolTip 
                                                className='username-icon' 
                                                content={createToolTipText("A authentication Code that you will be able to reuse!")} 
                                                direction='up'
                                                >
                                                <HiOutlineInformationCircle/>
                                            </GPSToolTip>
                                        </div>
                                        <InputGroup className="mb-3">
                                            <Form.Control className={`username-lookup-form-${theme}`}
                                                style={{backgroundColor: usernameLookupBackgroundColor, color: usernameLookupColor}}
                                                id="basic-url" aria-describedby="basic-addon3" placeholder='Enter Auth Code...'
                                                onChange={(e) => {handleAuthCodeChange(e.target.value)}}
                                            />
                                        </InputGroup>
                                    </div>
                                </Card.Body>
                                <Card.Body className={`cul-card ${textTheme} justify-content-end`}>
                                    <>
                                        {
                                            saveMessage !== '' && (<p className="auth-code-msg">{saveMessage}</p>)
                                        }
                                    </>
                                    <Button className="save-btn" 
                                        value={Number.isNaN(authCode) ? '' : authCode}
                                        disabled={isSaveButtonDisabled()}
                                        onClick={() => {
                                            saveAuthenticatorCode()
                                        }}
                                        >
                                        Save
                                    </Button>
                                </Card.Body>
                                <Card.Footer className="text-muted">© {currentYear} - devSHACK</Card.Footer>
                            </Card>
                        </div>
                    )
                }
            </>
        </div>
    );
}

export default AuthenticatorCode;