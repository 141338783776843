import * as actionTypes from './actionTypes'

const initialState: MatrixState = {
    enabled: true
}

const reducer = (
    state: MatrixState = initialState,
    action: MatrixAction
): MatrixState => {
    switch (action.type) {
        case actionTypes.TOGGLE_MATRIX:
            return {
                ...state,
                enabled: state.enabled
            }    
        default:
            break;
    }
    return state
}

export default reducer