import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Modal, Row, Spinner } from 'react-bootstrap';
import { APIBaseURL } from '../models';
import { clientUserPermission, permission } from '../models/clientUserPermission';
import { AiOutlineUser } from 'react-icons/ai';

export interface IPermissionFilterModal {
    isShowing: boolean
    darkThemeEnabled: boolean
    onClose: () => void
    onSubmit: (permissionId: number) => void
}

const PermissionFilterModal: React.FunctionComponent<IPermissionFilterModal> = props => {
    const buttonVariantTheme = props.darkThemeEnabled ? 'secondary' : 'outline-dark';
    const closeButtonTheme = props.darkThemeEnabled ? 'white' : '';
    const tableVariant = props.darkThemeEnabled ? 'dark' : '';
    const modalTheme = props.darkThemeEnabled ? 'bg-dark' : 'bg-light';
    const theme = props.darkThemeEnabled ? 'dark' : 'light';

    const [currentCategory, setCurrentCategory] = useState<clientUserPermission | null>(null);
    const [clientUserPermissions, setClientUserPermissions] = useState<clientUserPermission[] | null>();
    let isLoading = !clientUserPermissions;
    useEffect(() => {
        axios.get(APIBaseURL + `/clientUserPermission/defaultPermissions`, {
            withCredentials: true
        })
        .then((response) => {
            var data = response.data
            var permisisonGroups = Object.keys(data);
            var retrievedPermissions: clientUserPermission[] = [];

            permisisonGroups.forEach(permissionCategory => {
                retrievedPermissions.push(
                    {
                        title: permissionCategory,
                        permissions: data[permissionCategory],
                        icon: AiOutlineUser
                    }
                );
                setClientUserPermissions(retrievedPermissions)
                checkPermissionNames(data[permissionCategory])
            });
        })
        .catch((e) => {});
    }, []);

    const checkPermissionNames = (permissionNames: permission[]) => {
        const hasNoSpaceRegex = /^[^\s]*$/gm;
        const addSpaceRegex = /([A-Z][a-z]*)/g;

        permissionNames.map((perm) => {
            if(perm.name.match(hasNoSpaceRegex)) {
                let result = '';
                perm.name.match(addSpaceRegex)?.forEach(group => {
                    if(group.length > 1) {
                        result += ` ${group} `
                    } else {
                        result += `${group}`
                    }
                });
                perm.name = result;
            }
        })
    }

    return (
        <Modal
            show={props.isShowing}
            centered
            onHide={props.onClose}
            dismissable
        >
            <Modal.Header className={`submit-changes-header-${theme}`} closeButton closeVariant={closeButtonTheme}>
                <Modal.Title>
                    Permission Filter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${modalTheme} submit-changes-body-${theme}`}>
                <p>
                Select a permission to find a client, who has a user with the 
                desired permission                           
                </p>
                <>
                    {
                        isLoading ? (<Spinner animation='border' className='spinner'/>)
                        : (
                            <Row>
                                <Col>
                                    <DropdownButton 
                                        id={`dropdown-basic-button-${theme}`} 
                                        title={currentCategory != null ? currentCategory!.title : 'Select a Category'} 
                                        variant={buttonVariantTheme} 
                                        menuVariant={tableVariant}
                                        >
                                        <div className='client-scroll permission-dropdown-item'>
                                            <>
                                                {
                                                    clientUserPermissions?.map((perm) => {
                                                        return (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setCurrentCategory(perm)
                                                                }}
                                                                >
                                                                {perm.title}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </>
                                        </div>
                                    </DropdownButton>
                                </Col>
                                <Col>
                                    <DropdownButton 
                                        id={`dropdown-basic-button-${theme}`} 
                                        title={`Select a Permission`} 
                                        variant={buttonVariantTheme} 
                                        menuVariant={tableVariant}
                                        disabled={!currentCategory}
                                        >
                                        <div className='client-scroll permission-dropdown-item'>
                                            <>
                                                {
                                                    currentCategory?.permissions.map((perm) => {
                                                        return (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setCurrentCategory(null);
                                                                    props.onSubmit(parseInt(perm.permissionId));
                                                                    props.onClose();
                                                                }}
                                                                >
                                                                {perm.name}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </>
                                        </div>
                                    </DropdownButton>
                                </Col>
                            </Row>
                        )
                    }
                </>
            </Modal.Body>
        </Modal>
    );
}

export default PermissionFilterModal;